import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import { userRoles } from "../services/constant";
import { clearLocalStorage, getUserDetails } from "../services/userStorage";
import UserContext from "../components/UserContext";
import "./AppMenu.css";

function AppMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation(); 
  const userContext = useContext(UserContext);
  const userDetails = getUserDetails();

  const getRole = (role) => {
    let roleData = userRoles.find((item) => item.value === role);
    return roleData ? roleData.name : "";
  };

 
  const isActive = (path) => location.pathname === path;

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen); 
  };

  return (
    <div>
      <div className="menu-container">
        <div>
          {/* Placeholder for any additional elements */}
        </div>
        <div className="hamburger-icon" onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Menu items container */}
        <div className={`menu-items ${isMenuOpen ? "active" : ""}`}>
          <button
            className={`menu-button ${isActive("/dashboard") ? "active" : ""}`}
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </button>
          <button
            className={`menu-button ${isActive("/home") ? "active" : ""}`}
            onClick={() => navigate("/home")}
          >
            Home
          </button>
          <button
            className={`menu-button ${isActive("/site") ? "active" : ""}`}
            onClick={() => navigate("/site")}
          >
            Sites
          </button>
          <button
            className={`menu-button ${isActive("/details") ? "active" : ""}`}
            onClick={() => navigate("/details")}
          >
            Details
          </button>
          <button
            className={`menu-button ${isActive("/support") ? "active" : ""}`}
            onClick={() => navigate("/support")}
          >
            Support
          </button>
          <button
            className={`menu-button ${isActive("/errors") ? "active" : ""}`}
            onClick={() => navigate("/errors")}
          >
            Errors
          </button>
          <button
            className={`menu-button ${isActive("/memory") ? "active" : ""}`}
            onClick={() => navigate("/memory")}
          >
            Memory
          </button>
          <button
            className={`menu-button ${isActive("/todo") ? "active" : ""}`}
            onClick={() => navigate("/todo")}
          >
            Todo
          </button>
          <button
            className={`menu-button ${isActive("/process") ? "active" : ""}`}
            onClick={() => navigate("/process")}
          >
            Processes
          </button>
          <button
            className={`menu-button ${isActive("/release") ? "active" : ""}`}
            onClick={() => navigate("/release")}
          >
            Releases
          </button>

          {userDetails && userDetails.role <= 2 && (
            <button
              className={`menu-button ${isActive("/user") ? "active" : ""}`}
              onClick={() => navigate("/user")}
            >
              Users
            </button>
          )}
          <button
            className="menu-button logout-button"
            onClick={() => {
              clearLocalStorage();
              userContext.doLogin();
              navigate("/login");
            }}
          >
            Logout
          </button>

          {userDetails && (
            <span className="user-details">
              {userDetails.name} ({getRole(userDetails.role)})
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppMenu;
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap';
import DeployDialog from './DeployDialog';
import moment from "moment";
import UserInfoBySite from './UserInfoBySite';
import { actionDropdown } from '../services/constant';

const TodoListTable = ({ items, doDelete, doEdit, doHistory, doUpgrade }) => {
    const [localItems, setlocalItems] = useState([...items]);
    const [toggleSorting, setToggleSorting] = useState(0);
    const [dynamicStyles, setDynamicStyles] = useState();
    const [deployData, setDeployData] = useState('');
    const [actionOption, setActionOption] = useState([]);
    const getFreeSpace = (free, size) => {
        const percentageFull = ((size - free) / size) * 100;
        const roundValue = Math.round(percentageFull);
        return roundValue;
    };

    useEffect(() => {
        const styles = `.cursor-pointer
          { cursor: pointer;}`;
        setDynamicStyles(styles);
        setlocalItems(items);
        setActionOption(actionDropdown);
    }, [items]);

    const getActionName = (value) => {
        if (value) {
          const action = actionOption.find((i) => i.value === value);
          return action ? action.name : "-";
        }
        return "-";
      };

    const sortTable = (field) => {
        let sortedData = [];

        if (toggleSorting === 1) {
            sortedData = [...localItems].sort((a, b) => a[field] > b[field] ? 1 : -1);
            setToggleSorting(0);
        } else {
            sortedData = [...localItems].sort((a, b) => b[field] > a[field] ? 1 : -1);
            setToggleSorting(1);
        }
        setlocalItems(sortedData);
    }
    const onDeploy = (rowData) => {
        setDeployData(rowData)
    }
    return (
        <div className="p-0 m-0 px-2">
            <style>{dynamicStyles}</style>
            <Table className="table table-hover" responsive hover >
                <thead>
                    <tr>
                        <th onClick={() => sortTable("name")} className="cursor-pointer">Name</th>
                        <th className="cursor-pointer">Domain</th>
                        <th className="cursor-pointer">Todo Action</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {localItems.map((item) => (
                        <tr key={item._id}>
                            <td>{item.name}</td>
                            <td><a href={item.link}>{item.domain}</a></td>
                            <td>{getActionName(item?.action?.actionType)}</td>
                            <td className="">
                                <div className="dropdown float-end pe-2">
                                    <a href="#" role="button" data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fa-solid fa-bars"></i>
                                    </a>
                                    <div className="dropdown-menu p-2">
                                        <ul className='d-flex p-0 m-0'>
                                            <i
                                                className="fa-solid fa-clock-rotate-left btn btn-warning m-2 "
                                                onClick={() => doHistory(item._id)}
                                            ></i>
                                            <i
                                                className="fas fa-edit btn btn-primary m-2 "
                                                onClick={() => doEdit(item._id)}
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                className="fa-solid fa-trash-can btn btn-danger m-2"
                                                onClick={() => doDelete(item._id)}
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                className="fa-solid fa fa-ellipsis-v btn btn-dark m-2"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                aria-hidden="true"
                                                onClick={() => setDeployData(item)}
                                            ></i>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <th></th>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <DeployDialog deployData={deployData} />
            <UserInfoBySite deployData={deployData} />
        </div>
    );
}
<style>
{`
    .table {
      table-layout: fixed;
      width: 150%;
    }

    .table td, .table th {
      white-space: normal;
      max-width: 200px; /* optional */
      text-overflow: ellipsis; /* optional */
    }

    .table a {
    }
  `}
</style>
export default TodoListTable

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { title } from "../config";
import { getSupportData } from "../services/supportAPI";

const Support = () => {
    const [support, setSupport] = useState([]);
    const [toggleSorting, setToggleSorting] = useState(0);
    const [dynamicStyles, setDynamicStyles] = useState();
    const [loading, setLoading] = useState(true);

    const sortTable = (field) => {
        const sortedData = [...support].sort((a, b) => {
            const compareResult = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            return toggleSorting === 1 ? -compareResult : compareResult;
        });
        setToggleSorting((prev) => (prev === 1 ? 0 : 1));
        setSupport(sortedData);
    };
    const reloadUser = async () => {
        let records = await getSupportData();
        setLoading(false)
        setSupport(records);
    };
    useEffect(() => {
        reloadUser()

    }, [])
    useEffect(() => {
        const styles = `.cursor-pointer
              { cursor: pointer;}`;
        setDynamicStyles(styles);
    }, []);
    return (
        <>
            <div>
                <div>
                    <div className="px-2" style={{ backgroundColor: "#f8f9fa" }}>
                        <h3 className="title m-0 p-2">Remote<span style={{ color: '#ff6501' }}>Xs</span> Monitor | Monitor | Support Queries</h3>
                        <AppMenu />
                    </div>
                    <span style={{marginLeft: '81vw'}}>
                        <b>Total:{support.length} &nbsp;&nbsp;</b>
                    </span>
                </div>
                {loading ?
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "75vh",
                        }}
                    >
                        <div className="spinner-border">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className='p-0 m-0 px-2'>
                        <style>{dynamicStyles}</style>
                        <Table className="table table-hover" responsive hover >
                            <thead>
                                <tr>
                                    <th className="cursor-pointer" onClick={() => sortTable("name")} >Name</th>
                                    <th className="cursor-pointer" onClick={() => sortTable("url")} >SiteURL</th>
                                    <th className="cursor-pointer" onClick={() => sortTable("email")}>Email</th>
                                    <th className="cursor-pointer" onClick={() => sortTable("phone")}>Phone</th>
                                    <th className="cursor-pointer" >Time-Date</th>
                                    <th className="cursor-pointer" >Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {support?.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.name} </td>
                                        <td>{item.url} </td>
                                        <td>{item.email} </td>
                                        <td>{item.phone} </td>
                                        <td>{item?.updatedTime ? new Date(item?.updatedTime).toLocaleTimeString() + ' - ' + new Date(item?.updatedTime).toLocaleDateString() : ""} </td>
                                        <td>{item.issue} </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </>)
}
<style>
{`
    .table {
      table-layout: fixed;
      width: 150%;
    }

    .table td, .table th {
      white-space: normal;
      max-width: 200px; /* optional */
      text-overflow: ellipsis; /* optional */
    }

    .table a {
    }
  `}
</style>
export default Support